import { getBulkProductsByIds } from '@/api/storefront';

import {
    MODULE_NAME as MODULE_ADDRESSES,
    GETTER_ADDRESSES_WITH_ITEMS
} from '@/store/dashboard/addresses/constants';

import {
    MODULE_NAME as MODULE_SHOPIFY_ORDERS,
    GETTER_ORDERS
} from '@/store/dashboard/shopify_orders/constants';

import {
    MODULE_NAME as MODULE_DASHBOARD_BOXES,
    GETTER_BOX_PRODUCT_IDS
} from '@/store/dashboard/boxes/constants';

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

import {
    STATE_PRODUCTS,
    GETTER_PRODUCTS,
    GETTER_VARIANTS,
    GETTER_MIGRATION_VARIANT_IDS,
    MUTATION_SET_ALL_PRODUCTS,
    ACTION_INIT_ALL_PRODUCTS,
    DEFAULT_STATE,
} from "@/store/dashboard/products/constants";

const identifiers = [
    {
        namespace: 'custom_fields',
        key: 'product_details_display'
    },
    {
        namespace: 'custom_fields',
        key: 'stats'
    },
    {
        namespace: 'custom_fields',
        key: 'best_suited_for'
    },
    {
        namespace: 'custom',
        key: 'split_by_options'
    },
    {
        namespace: 'loox',
        key: 'num_reviews'
    },
    {
        namespace: 'loox',
        key: 'avg_rating'
    }
];

const productFields = {
    id: true,
    availableForSale: true,
    createdAt: false,
    description: false,
    descriptionHtml: false,
    featuredImage: true,
    handle: true,
    isGiftCard: false,
    onlineStoreUrl: false,
    options: true,
    productType: false,
    publishedAt: false,
    requiresSellingPlan: true,
    sellingPlanGroups: true,
    seo: false,
    tags: true,
    title: true,
    totalInventory: true,
    updatedAt: false,
    vendor: false,
    images: false,
    media: false,
    variants: {
        availableForSale: true,
        barcode: false,
        compareAtPrice: true,
        id: true,
        image: true,
        price: true,
        quantityAvailable: true,
        requiresShipping: true,
        selectedOptions: true,
        sku: false,
        title: false,
        weight: false,
        weightUnit: false,
        metafields: true,
    },
    metafields: true,
};

const variant_identifiers = [
    {
        namespace: 'box',
        key: 'discount_value'
    },
    {
        namespace: 'migration',
        key: 'variant_id'
    }
];

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_PRODUCTS](state) {
            return Object?.values(state[STATE_PRODUCTS] || {})?.reduce((products, product) => {
                const id = product?.id;
                if (!id) {
                    return products;
                }

                return {
                    ...products,
                    [id]: product
                };
            }, {}) || {};
        },
        [GETTER_VARIANTS](state, getters) {
            const products = getters[GETTER_PRODUCTS];
            if (!products) {
                return {};
            }

            return Object?.values(products)?.reduce((variants, product) => {
                return {
                    ...variants,
                    ...((product?.variants || [])?.reduce((variants, variant) => {
                        return {
                            ...variants,
                            [variant?.id]: variant,
                        };
                    }, {}) || {}),
                };
            }, {}) || {};
        },
        [GETTER_MIGRATION_VARIANT_IDS](state, getters) {
            const products = getters[GETTER_PRODUCTS];
            if (!products) {
                return [];
            }

            return products?.reduce((variants, product) => [
                ...variants,
                ...(product?.variants || [])
            ], [])?.reduce((migration_ids, variant) => {
                const id = variant?.metafields?.migration?.variant_id;
                if (!id) {
                    return migration_ids;
                }

                return {
                    ...migration_ids,
                    [id]: variant?.id,
                };
            }, {}) || {};
        }
    },
    mutations: {
        [MUTATION_SET_ALL_PRODUCTS](state, products) {
            state[STATE_PRODUCTS] = products;
        }
    },
    actions: {
        async [ACTION_INIT_ALL_PRODUCTS](context) {
            try {
                const addresses = context.rootGetters[`${MODULE_ADDRESSES}/${GETTER_ADDRESSES_WITH_ITEMS}`];

                const subscription_product_ids = Object?.values(addresses)?.reduce((product_ids, address) => {
                    const subscriptions = Object?.values(address?.subscriptions) || [];
                    if (!subscriptions) {
                        return product_ids;
                    }

                    if (!subscriptions?.length) {
                        return product_ids;
                    }

                    return [
                        ...product_ids,
                        ...(subscriptions?.map((subscription) => {
                            return parseInt(subscription?.external_product_id?.ecommerce);
                        }) || []),
                    ];
                }, []) || [];

                const orders = context.rootGetters[
                    `${MODULE_SHOPIFY_ORDERS}/${GETTER_ORDERS}`
                ];

                const order_product_ids = Object?.values(orders)?.reduce((product_ids, order) => {
                    const line_items = order?.line_items;
                    if (!line_items) {
                        return product_ids;
                    }

                    return [
                        ...product_ids,
                        ...(line_items?.map((line_item) => {
                            return line_item?.product_id;
                        })?.filter((id) => {
                            return !!id;
                        }) || [])
                    ];
                }, []) || [];

                const box_product_ids = context.rootGetters[`${MODULE_DASHBOARD_BOXES}/${GETTER_BOX_PRODUCT_IDS}`];

                const products = (
                    await getBulkProductsByIds([
                        ...new Set([
                            ...subscription_product_ids,
                            ...order_product_ids,
                            ...box_product_ids
                        ])
                    ]?.filter((id) => {
                        return !!id;
                    }) || [], {
                        identifiers,
                        variant_identifiers,
                    }, productFields)
                )?.filter((product) => {
                    return !!product;
                }) || [];

                context.commit(MUTATION_SET_ALL_PRODUCTS,
                    products?.reduce((products, product) => {
                        const id = product?.id;
                        if (!id) {
                            return products;
                        }

                        products[id] = product;

                        return products;
                    }, {}) || {});
            } catch (error) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error fetching all products`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}
            }
        }
    }
};
